import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
//rotas
import { HashRouter as Router, Routes, Route } from "react-router-dom";
//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ColorHexPrivacy from "./pages/ColorHexPrivacy";
import Terms from "./pages/Terms";
//componentes
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/NotFound";
import { ColorModeContext, useMode } from "./theme";
import Loading from "./components/Loading";
import Notification from "./components/Notification";

import { useValue } from "./components/ContextProvider";

function App() {
  const [theme, colorMode] = useMode();
  const [isFetching, setIsFetching] = useState(true);
  const { dispatch } = useValue();
  useEffect(() => {
    dispatch({
      type: "START_LOADING",
    });
    setTimeout(function () {
      console.log("Delayed for 5 second.");
      dispatch({
        type: "END_LOADING",
      });
      setIsFetching(false);
    }, 2000);
  }, []);

  if (isFetching) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Loading />
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loading />
        <Notification />
        <Router baseline="/">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/ColorHex/privacy" element={<ColorHexPrivacy />} />
            <Route path="/ColorHex/termsAndConditions" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <Footer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
