import React, { useState } from "react";
import { Box, Button, Stack, TextField, Alert } from "@mui/material";
import Title from "./Title";
import Paragraph from "./Paragraph";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import emailjs from "emailjs-com";
import { useValue } from "./ContextProvider";

const Details = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const { dispatch } = useValue();

  const serviceID = "default_service";
  const templateID = "template_k76gvyv";

  emailjs.init("vI73VDZNmI2xinaTt");

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, phone } = formState;

    // Validation
    if (!name || !email || !phone) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Please fill in all fields.",
        },
      });
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Please enter a valid email address.",
        },
      });
      return;
    }

    // Validate phone number format (You can customize this regex as per your requirement)
    const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (!phoneRegex.test(phone)) {
      dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Please enter a valid phone number.",
        },
      });
      return;
    }

    const templateParams = {
      user_name: name,
      user_email: email,
      user_phone: phone,
    };

    emailjs
      .send(
        serviceID,
        templateID,
        templateParams
      )
      .then(
        (response) => {
          dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "success",
              message: "Email sent successfully!",
            },
          });
          setFormState({ name: "", email: "", phone: "" });
        },
        (error) => {
          dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: "Error sending email. Please try again.",
            },
          });
        }
      );
  };

  return (
    <>
      <Stack
        component="section"
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          py: 10,
          px: 2,
        }}
      >
        <Title text={"Interested in our service?"} textAlign={"center"} />
        <Paragraph
          text={
            "If you are interested in using our service, contact us. We will call you shortly to fulfill your requirements."
          }
          maxWidth={"sm"}
          mx={0}
          textAlign={"center"}
        />

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            py: 2,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            autoComplete="name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formState.email}
            onChange={handleChange}
            autoComplete="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="Phone Number"
            type="tel"
            id="phone"
            value={formState.phone}
            onChange={handleChange}
            autoComplete="tel"
          />
          <Button
            variant="contained"
            fullWidth
            type="submit"
            size="medium"
            sx={{
              fontSize: "0.9rem",
              textTransform: "capitalize",
              py: 2,
              mt: 3,
              mb: 2,
              borderRadius: 0.5,
              borderColor: colors.redAccent[600],
              color: colors.primary[100],
              backgroundColor: colors.redAccent[500],
              "&&:hover": {
                backgroundColor: colors.redAccent[300],
              },
              "&&:focus": {
                backgroundColor: colors.redAccent[300],
              },
            }}
          >
            Send
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default Details;
