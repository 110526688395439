const reducer = (state, action) => {
    switch (action.type) {

      case 'START_LOADING':
        return { ...state, loading: true };
      case 'END_LOADING':
        return { ...state, loading: false };
  
      case 'UPDATE_ALERT':
        return { ...state, alert: action.payload };
  
 
  
      default:
        throw new Error('No matched action!');
    }
  };
  
  export default reducer;
  